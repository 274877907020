@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(179, 179, 179, 0.621);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .weather {
    @apply w-[300px] rounded-md shadow-currentShadow text-white bg-[#333] mt-5 mb-0 mx-auto px-5 pt-0 pb-5;
  }

  .menu {
    @apply flex justify-between items-center;
  }
  .temperature {
    @apply font-semibold text-6xl w-auto my-2.5 tracking-tighter;
  }
  .parameter-row {
    @apply flex justify-between;
  }

  .parameter-label {
    @apply text-left text-xs;
  }
  .parameter-value {
    @apply text-right font-semibold text-xs;
  }

  .title {
    @apply text-[23px] font-bold;
  }

  .daily-item {
    @apply bg-[#f5f5f5] cursor-pointer rounded-2xl h-10 m-[5px] items-center flex py-[5px] px-4 xs:px-2;
  }

  .icon-small {
    @apply w-10;
  }

  .daily-item .day {
    @apply text-[#212121] cursor-pointer flex-1 font-semibold ml-[15px] sm:ml-[0px];
  }

  .description {
    @apply flex-1 mr-[15px] cursor-pointer text-right xs:mr-[0px] sm:mr-[5px] sm:text-xs;
  }

  .min-max {
    @apply text-[#757575] cursor-pointer xs:text-sm;
  }
  .daily-details-grid {
    @apply bg-red-200 rounded-xl cursor-pointer grid gap-x-[15px] flex-1 grid-cols-2 py-[5px] px-[15px] gap-y-0;
  }

  .daily-details-grid-item {
    @apply items-center flex justify-between h-[30px] xs:text-sm;
  }

  .daily-details-grid-item label:first-child {
    @apply text-[#757575];
  }

  .daily-details-grid-item label:last-child {
    @apply text-[#212121];
  }
}
