button {
  position: relative;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  width: 100px;
  height: 40px;
  overflow: hidden;
  border-radius: 40px;
}

button span {
  position: relative;
  color: #fff;
  font-size: 20px;
  font-family: Arial;
  padding-left: 8px;
  letter-spacing: 8px;
  z-index: 1;
}

button .liquid {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgb(252 165 165);
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 3.5s;
}

button .liquid::after,
button .liquid::before {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  background: #000;
}

button .liquid::before {
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 5s linear infinite;
}

button .liquid::after {
  border-radius: 40%;
  background: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

button:hover .liquid {
  top: -60px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@media screen and (max-width: 420px) {
  button {
    margin: 10px auto 0 auto;
    width: 200px;
  }

  button .liquid {
    top: -80px;
    width: 200px;
    height: 200px;
  }

  button .liquid::after,
  button .liquid::before {
    width: 200%;
    height: 200%;
    top: 0;
    left: 50%;
  }

  button:hover .liquid {
    top: -120px;
  }
}
